.profile_data{
  background-color:white;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.15);
  /* padding: 0px 25px 25px 5px; */
  border-radius: 10px;
}
.profile-title {
  font-size: 20px;
  padding: 10px;
  color: rgb(31, 108, 196);
}
.pr_label {
  font-weight: bold !important;
  font-size: 16px !important;
  margin: 10px !important;
  font-family: 'Roboto', sans-serif;
}
.pr_title {
  margin-left: 20px !important;
  font-family: 'Roboto', sans-serif;
  font-weight:400;
}
.logout_card {
  padding: 12px;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #161515 !important;
  background-color: #f5f5f5;
}
