/* Dashboard.css */
.dashboard-container {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Set the height of the container to the full viewport height */
    gap: 20px;
    width: 100%;
  }
  
  .line-container {
    display: flex;
    justify-content: space-between;
    gap:10px;
    width:100%

  }

  /* @media screen and (min-device-width: 200px) and (max-device-width: 640px) {
    .line-container {
      width:100%;
      display: none;
      justify-content: space-between;
      gap:10px;
    }
  }
   */
  .first-div {
    width: 80%;
    height: 31vh;
    background-color: white; /* Set your desired background color */
    border-radius: 10px;
    border: 1px solid #F8F9FA;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
    gap:1em;
    padding: 1%; 
    overflow: scroll;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

  }
  .data_dashboard{
    height: 8rem;
    background-color:#F2FCF4;
    padding: 10px;
    border-radius: 15px;
  }
  
  .second-div {
    width: 19%;
    height: 31vh;
    background-color: white; /* Set your desired background color */
    border-radius: 10px;
    border: 1px solid #F8F9FA;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);



  }
  .heading_of_dashboard{
    width: 80%;
    display: flex;
    justify-content: space-between;
    padding: 0% 0% 1% 0%;
  }
  .heading_of_dashboard_second{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .dashboard-heading{
      font-size: 16px;
      font-family: Roboto;
      align-content: center
  }
  .table-div{
    width: 100%;
    height: 120%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: 1px solid #F8F9FA;
  }
  .table-div_with_heading{
    width: 80%;
    height: 120%;
    border-radius: 10px;
  }
  .table-graph-div{
    width: 19%;
    height: 31vh;
    background-color:white; /* Set your desired background color */
    border-radius: 10px;     
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

    border: 1px solid #F8F9FA;

  }

  .card_of_dashboard{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px 15px 10px ;
  }
  .card_of_concent{
    display: inline-flex;
  }

.card_text{
  color: #8E8E8E;
  font-size: 13px;
}
.card_heading_text{
  font-size: 18px;
  font-weight: 500;
  padding:0px 10px 0px 10px;
}
.card_subheading_text{
  color: #0038FF;
  font-size: 13px;

}
.card_heading_second{
  font-size: 18px;
  font-weight: 500;
  padding:0px 10px 0px 0px;
}