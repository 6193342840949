@import "rsuite/dist/rsuite.css";

.rs-picker-menu{
  z-index: 1250 !important;
}
.rs-picker-toggle input {
  -webkit-autofill {
    display: none;
  }
}
.rs-anim-fade.rs-anim-in{
  z-index: 1300 !important;
}

#root{
  height: 100vh;
  width: 100vw;
}
*{
  margin: 0;
  padding: 0;
}
.app{
  height: 100%;
  overflow: hidden; 
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

::-webkit-scrollbar {
  display: none;
}

body{
  background-color: #FFFCFA;
}
.bottom_div{
position: absolute;
bottom: 0;
}
.custom-header {
  background-color:#2C8E14; 
}
.custom-row {
  background-color: inherit !important; 
  border: none;
}

.MuiDataGrid-row.Mui-selected {
  background-color: inherit !important; 
  border: none !important; 
  box-shadow: none !important; 
  outline: none !important;
}

.no-border {
  border: none;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color:#EF990F;
  padding:8px;
}



