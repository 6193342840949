.account_container {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  font-family: Roboto;
}
.account_1{
  overflow-y: hidden;
  max-height: calc(100vh - 150px); 

}
.account_1::-webkit-scrollbar{
  width: 0;
}
.account1_title::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar */
}
.account1_title::-webkit-scrollbar-track {
  background: transparent; /* Adjust as needed */
}

.account1_title::-webkit-scrollbar-thumb {
  background: gray; /* Adjust as needed */
}
.account_data_fortab {
  width: 100%;
  background-color: white;
  height: 100%;
  border-radius: 10px;
}
.paper{
  background-color: #338514;
  color:white
}
.live{
  background-color: #338514;
  color:white
}
.token_btn a{
  color:'white !important';
}
@media (max-width: 1600px) {
  .account_data {
    width: 100%;
  }
}

.acc_btn {
  width: 150px;
  height: 37px;
  border-radius: 5px;
  margin-left: 10px;
  font-weight: bolder;
  box-shadow: rgba(0, 0, 0, 0.15) 6px 6px 7px;
  font-size: 19px;
  text-transform: capitalize !important;
}
.account_2 p {
  color: blue;
  font-weight: bold;
  font-size: 20px;
  padding: 5px;
}
.lot_data {
  display: flex;
  /* padding: 5px; */
  align-items: center;
  justify-content: space-between;
}
.lot_data .lot_data_title p {
  color: black;
}
.lot_data .lot_data_title p span {
  color: rgb(31, 108, 196);
}
p {
  color: #161515;
}

.trade-body {
  border-radius: 30px;
}
.card_title {
  display: flex;

  justify-content: space-between;
  align-items: center;
}
.account1_titlefortab {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
  gap:2em;
  width: 100%;
  padding: 1em;
  height: 90%;
 
}



.icon_container {
  display: flex;
  justify-content: flex-end;
  margin: 15px 15px 0px 15px;
  gap: 20px;
}



.accoun_title {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.trade_list {
  display: inline;
  gap: 10px;
}
.trade_list .card_title h6 {
  font-size: 19px;
  color: black;
  font-weight: 600;
}
.fab_btn {
  margin-top: -3px;
  align-self: flex-end;
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
}

.button_container {
  display: flex;
  justify-content: center;

  margin-top: 1px;
  gap: 20px;
  padding: 15px 0;
}

.select_trade {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.trading_detail {
  display: flex;

  padding: 10px 0;
  align-items: center;
  justify-content: space-between;
  gap: 6.5px;
}
.trading_detail p {
  color: black;
  display: flex;
  font-size: 14px;
  column-gap: 4px;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0 !important;
}

.trading_detail p span {
  color: rgb(31, 108, 196);
  word-wrap: break-word;
  font-size: 12px;
  text-overflow: ellipsis;
  width: 60px;
  white-space: nowrap;
  overflow-x: hidden;
}

.card_content {
  margin: 10px;
  justify-content: center;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 25px !important;
  padding: 12px 12px !important;
}

@media screen and (max-device-width: 768px) {
  .card_content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .account1_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: -30px;
    margin-bottom: 50px;
  }
  .account_container {
    display: flex;
    margin-top: -22px;
    font-family: 'Roboto', sans-serif;
    min-width: 40px;
    justify-content: center;
  }
  .account_2 p {
    color: blue;
    font-weight: bold;
    font-size: 18px;
    margin-top: 0 !important;
  }
  .trading_detail {
    justify-content: space-between;
    padding: 0;
  }
  .card_content {
    display: flex;
    justify-content: center;
    margin: 10px 10px;
    padding-right: 30px;
    font-size: 18px;
  }

  .icon_container {
    display: flex;
    justify-content: flex-end;
    margin: 14px 20px;
    gap: 20px;
  }

  .trading_detail p {
    font-size: 16px;
    padding: 0;
    display: flex;
    flex-direction: column;
    color: black;
    gap: 4px;
  }
  .accountfab_btn {
    position: relative;
    top: 0;
  }

  .fab_btn {
    padding: 5px 5px;
    position: absolute;
    color: white;
    border: none;
    cursor: pointer;
  }

  .trade_list .card_title h6 {
    font-size: 16px;
  }
  .card_title {
    display: flex;
    margin: 5px 0;
    justify-content: space-between;
  }
  .trading_detail2 {
    margin-left: 25px;
  }
  .lot_data {
    padding: 0;
    margin: 5px 0;
    align-items: center;
  }
  .lot_data p {
    margin-right: 10px;
  }

  .select_option {
    display: flex;
    flex-direction: column;
    width: auto;
  }
 

  
  .account_data p {
    color: rgb(31, 108, 196);
  }
}
