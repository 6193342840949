.addalgoform::-webkit-scrollbar {
    width: 10px; 
    display: none;
    background-color: aqua;
  }

  @media screen and (min-height: 150%) {
    .addalgoform {
      overflow-y: auto;
      max-height: 500px;
    }
  }
 
  @media screen and (min-height: 110%) {
    .addalgoform {
      overflow-y: auto;
      max-height: 700px;
    }
  }
  

  @media screen and (min-height: 100%) {
    .addalgoform {
      overflow-y: hidden;
    
    }
  }

  .css-heg063-MuiTabs-flexContainer{
    overflow-x: auto !important;
  }
  .css-k008qs{
    overflow-x: auto !important;

  }