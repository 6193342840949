/* Normal view For login screen */
.app {
  height: 100%;
}

.login_form_1{
  width: 100%;
}
.form_field{
  width: 65%;
  margin: 10px 0px 10px 0px ;
}
.form_email{
  width: 65%;
}
.checkboxstyle{
  width:65%
}
.Loginbuttonstyle{
  width: 65%;
  height: 40px;
  font-size:20px;
  margin-top: 2%;
  border-radius: 4px;
  font-weight: bolder;

}
.login_image {
  display: flex;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
}
.login_image_container {
  width: 50%;
  height: 100%;
}
.login_validation {
  width: 50%;
  height: 100%;
}
.Login_images_1 {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  width: 100;
  align-items: center;
  justify-content: center;
}

.log_form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login_title {
  font-weight: 50px;
  font-size: 30px;
  line-height: 23px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 50px;
  overflow: hidden;
}
.login_data {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 10px;
  text-transform: capitalize;
}

.login_hello {
  font-weight: 300;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  color: black;
}
.login_welcome {
  font-family: 'Roboto', sans-serif;

  font-size: 24px;
  font-weight: 600;

  display: flex;
  align-items: center;
  color: black;
}
.login_acc {
  font-weight: 300;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  color: rgb(22, 19, 19);
  text-align: center;
  margin-bottom: 70px;
}
.login_logo {
  margin-bottom: 20px;
  height: 70px;
}

@media screen and (min-device-width: 200px) and (max-device-width: 640px) {
  .login_logo {
    margin-bottom: 20px;
    height: 70px;
    width: 170px;
  }
}


.LoginImages::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}
.LoginImages {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.LoginImage {
  width: 100%;
  height: 100%;
}
.LoginImages {
  width: 100%;
  display: flex;
  justify-content: center;
}
.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;

  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
}

@media screen and (min-device-width: 1920px) {
  .Login_images_1 {
    height: 100%;
    padding: 5px 5px;
  }
  .LoginImages {
    width: 100%;
    height: 100%;
    align-items: center;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .login_image_container {
    display: none;
    width: 100%;
    height: 100%;
  }
  .login_form {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
  }
  .form_email{
    width: 90%;
  }
  .Loginbuttonstyle{
    width: 90%;
    height: 40px;
    font-size: 15px;
 margin-top: 60px;
  border-radius: 4px;
  font-weight: bolder;

  }
 
  .form_field{
    width: 90%;
    margin: 10px 0px 10px 0px ;
    
  }
  .checkboxstyle{
    width:90%
  }
  .login_logo {
    display: flex;
    width: 50%;
    height: 6%;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 15px;
  }
  .login_validation {
    width: 100%;
    height: 100%;
  }
  .login_hello{
    font-weight: 300;
    font-size: 24px !important;
    font-family: 'Roboto', sans-serif;
    color: black;
  }
  .login_welcome {
    font-family: 'Roboto', sans-serif;

    font-size: 24px;
    font-weight: 600;

    display: flex;
    align-items: center;
    color: black;
  }
  .login_acc {
    font-weight: 200;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;

    color: black;
    text-align: center;
  }
  .login_form_1{
    width: 100%;
    font-size: 15px !important;
  }
 
 
}
