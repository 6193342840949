.userform::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
  display: none;
}
/* Enable scrollbar when display size is 150% or larger */
@media screen and (min-height: 150%) {
  .userform {
    overflow-y: auto;
    max-height: 500px;
  }
}

/* Enable scrollbar when display size is 110% or larger */
@media screen and (min-height: 110%) {
  .userform {
    overflow-y: auto;
    max-height: 700px;
  }
}

/* Disable scrollbar when display size is 100% */
@media screen and (min-height: 100%) {
  .userform {
    overflow-y: hidden;
    /* or overflow-y: auto; if you want scrolling */
  }
}
.adduserlist{
  margin-bottom: 20px;
  display: grid;
  gap: 1em 1em;
  grid-template-columns: repeat(auto-fill,minmax(20rem,1fr));
}
