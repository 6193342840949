.total{
    background-color: #e9e6e6;
    height: 40px;
    width: 100%;
    border-radius: 0px 0px 5px 5px;
    display: flex;
    justify-content: flex-end;
}
.totaldiv{
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orderhanding{
    height: 75px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 20px
}
.orderhanding:hover{
    border: 1px solid #F4A717;

}
.ordertitle{
    border: 1px solid rgb(221, 220, 220);
    display:flex; 
    width:100%;
    border-radius:5px
}