.strategy_form::-webkit-scrollbar {
    width: 10px; 
    display: none;
  }

  @media screen and (min-height: 150%) {
    .strategy_form {
      overflow-y: auto;
      max-height: 500px;
    }
  }
 
  @media screen and (min-height: 110%) {
    .strategy_form {
      overflow-y: auto;
      max-height: 700px;
    }
  }
  

  @media screen and (min-height: 100%) {
    .strategy_form {
      overflow-y: hidden;
    
    }
  }
  

  .addstrategies {
    margin-bottom: 20px;
    display: grid;
    gap: 1em 1em;
    grid-template-columns: repeat(auto-fill,minmax(20rem,1fr));
  }

 
.css-1z0v8z8-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: var(--border-color) !important;   }

  .css-rjkf6j.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: var(--border-color) !important; 
  }

  /* timepicker */
  /* .css-7z6mfr-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .css-u1zs5w.Mui-error .MuiOutlinedInput-notchedOutline{
    border: none !important;

  } */