/* date mulitselect  */
.rmdp-panel-body li .b-deselect{
  padding:0px 10px 0px 10px !important ;
  background-color:#FFE5B5 !important;
  color: black !important;
}
.rmdp-panel-body li .b-date{
  background-color:#FFE5B5 !important;
  color: black !important;
}
.rmdp-day.rmdp-selected span:not(.highlight){
  background-color:#FFE5B5 !important;
  color: black !important;
}
.rmdp-day.rmdp-today span{
  background-color: white !important;
  color: black !important;
}
.ck-editor__editable {
  min-height: 150px !important;
  max-height: 400px !important;
}
.imageSize {
  height: 20%;
  width: 20%;
  margin-top: 7%;
}
.top-edit-button button {
  background-color: black !important;
  outline: none;
  border: none;
  border-radius: 0px !important;
  color: white;
  width: 150px;
  height: 40px;
}
.edit-image button {
  border: none;
  outline: none;
  background-color: #f5f5f5 !important;
  border-radius: 50% !important;
}

/* Font page */
.fontscrollbar {
  background-color: white;
  height: 93%;
  overflow: auto;
}
.fontscrollbar::-webkit-scrollbar {
  display: none;
}
.fontscrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media only screen and (max-width: 1059px) and (min-width: 10px) {
  .welcomeconent {
    margin-top: 1vw;
    display: grid;
    grid-template-columns: 100% !important;
   
    width: 100% !important;

  }
}

@media only screen and (max-width: 1200px) and (min-width: 1060px) {
  .welcomeconent {
    margin-top: 1vw;
    display: grid;
    grid-template-columns: 50% 50% !important;
    width: 100% !important;
  }
}

.welcomeconent {
  margin-top: 1vw;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  width: 100%;
  margin: 0 auto;
  padding: 15px;
}
.conent2:hover {
  background-color: #f5f5f5;
}
.conent {
  width: auto;
  padding: 10px;
  height: 190px;
}
.conent1 {
  width: 100%;
  height: 100%;
  color: white;
}
.fontname {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  padding-left: 10px;
  color: #000000;
}

.Welcomemessage {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 12px;
  padding: 10px;
  color: #000000;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.Name {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.Number {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  padding: 10px 10px 10px 10px;
  margin-bottom: 20px;
  display: block;
  text-align: center;
  border: 0.5px solid #000000;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  color: #ffffff;
}

.conent2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  color: white;
  border: 0.5px solid #000000;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
}
.conentspan {
  font-size: clamp(0.5rem, 1.5vw, 3rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.indexconent {
  padding: 10px;
  height: 400px;
}
.logoconent {
  width: 100%;
  height: 100%;
  margin: 65px 0px 0px 10px;
}
.logoconent2 {
  height: 100%;
  display: flex;
  align-items: "center";
  justify-content: center;
  flex-direction: column;
  margin-top: -3.5rem;
  background-color: white;
  width: 329px;
  background-repeat: no-repeat;
  color: white;
  border-radius: 5px;
  border: 1px solid gray;
}
.imglogo {
  border-radius: 5px;
  margin-top: 16px 0px 0px 0px;
}
.logospan {
  text-align: center;
}

.type {
  background-color: blue;
}

/* Question page  */
@media only screen and (max-width: 1059px) and (min-width: 10px) {
  .Questionpageconent {
    margin-top: 1vw;
    display: grid;
    grid-template-columns: 100% !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 1060px) {
  .Questionpageconent {
    margin-top: 1vw;
    display: grid;
    grid-template-columns: 100% !important;
    width: 100% !important;
  }
}

.Questionpageconent {
  margin-top: 1vw;
  display: grid;
  grid-template-columns: 100%;
  margin: 0 auto;
  padding: 25px; 
}

/* comicsBook page  */
.fontnamecenter {
  display: contents;
}

.button-button-view {
  text-transform: none;
  border-radius: 0;
  color: #000000;
  width: 120px;
  height: 25px;
  background-color: white;
  border: 1px solid black;
}

.cardDiv {
  display: inline-block;
  position: relative;
}

.cardDiv:hover img {
  box-shadow: #d3d3d3 3px 3px 3px;
  z-index: 1 !important;
  position: relative;
}

.Comicsname {
  font-weight: bold;
  color: #000000;
}
.ComicsDiscerption {
  width: 200px;
  color: #000000;
  font-size: 12px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}

.clear:hover {
  background-color: rgb(76, 241, 11);
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

/* campaign  page  */
.hover-effect:hover {
  color: white;
  background-color: black;
}

/* search  box   desgin  */
.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter
  .MuiInput-underline:before {
  border-bottom: none !important;
}
.css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter
  .MuiInput-underline:after {
  border-bottom: none !important;
}



/* .MuiDataGrid-root .custom-row .MuiDataGrid-cell:focus {
  outline: none;
}


.css-16y6xj5-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: white !important;
} */
@media screen and (min-device-width: 800px) and (max-device-width: 1366px) {
  .DialogTitleimg {
    width: 60px;
  }
}

@media screen and (min-device-width: 1400px) and (max-device-width: 1929px) {
  .DialogTitleimg {
    width: 16%;

  }
}
@media screen and (min-device-width: 1930px) and (max-device-width: 2000px) {
  .DialogTitleimg {
    width: 16%;
  }
}
@media screen and (min-device-width: 0px) and (max-device-width: 799px) {
  .DialogTitleimg {
    width: 40px;    
  }
}


/* column filter  in grid */


/* @media screen and (min-device-width: 0px) and (max-device-width: 1300px) {
  .css-n3z9fz-MuiPopper-root-MuiDataGrid-panel{
    display: flex !important;
    width: 55% !important;
    justify-content: flex-end !important;
    height: 45%;

  }
}@media screen and (min-device-width: 1301px) and (max-device-width: 4000px) {
  .css-n3z9fz-MuiPopper-root-MuiDataGrid-panel{
    display: flex !important;
    width: 80% !important;
    justify-content: flex-end !important;

  }
}


@media screen and (min-device-width: 0px) and (max-device-width: 1300px) {
  .css-1mtsuo7{
    display: flex !important;
    width: 55% !important;
    justify-content: flex-end !important;
    height: 45%;
  
  }
}@media screen and (min-device-width: 1301px) and (max-device-width: 4000px) {
  .css-1mtsuo7{
    display: flex !important;
    width: 80% !important;
    justify-content: flex-end !important;
  
  }
} */







