/* .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 12px;
    padding-bottom: 17px;
} */
.rs-picker-toggle-caret{
    font-size: 20px;
    margin-top: 7px;
}
.rs-picker-toggle input {
    -webkit-autofill {
      display: none;
    }
  }

  /* Add this to your styles or stylesheet */
.custom-date-range-picker .rs-picker {
  height: 3rem; /* Set the height */
  z-index: 1300;

}

.custom-date-range-picker .rs-picker-toggle,
.custom-date-range-picker .rs-picker-toolbar {
  height: 3rem; /* Set the height */
  z-index: 1300;
}

.rs-stack-item{
  padding:5px !important;
}
.rs-input-group.rs-input-group-inside {
  height: 56px !important;
}