.trade_container {
    height: 100%;
    background-color:white;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.15);
    padding: 25px;
    border-radius: 10px;
  }
  .trade_title {
    color: rgb(31, 108, 196);
  }
  .trade_body {
    display: flex;
    column-gap: 460px;
    margin-top: 40px;
  }
  .trade_body2 {
    display: flex;
    gap: 20px;
  }
  .search_container {
    border-radius: 10px !important;
  }
  @media screen and (min-device-width: 320px) and (max-device-width: 425px){
    .trade_container{
      height: 100%;
      width: 100%;
      margin: 10px;
    }
    .trade_body{
      display: flex !important;
      column-gap: 30px !important;
    }
  }