.account-containers {
  display: flex;
  justify-content: space-between;

  padding: 40px 0px 0px 0px;

  border-radius: 5px;

  font-family: 'Roboto', sans-serif;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.account_1{
  overflow-y: hidden;
  max-height: calc(100vh - 150px); 
}
.account1::-webkit-scrollbar {
  width: 0; /* Hide the scrollbar */
}
.account1::-webkit-scrollbar-track {
  background: transparent; /* Adjust as needed */
}

.account1::-webkit-scrollbar-thumb {
  background: gray; /* Adjust as needed */
}
.account-contain {
  display: flex;
  justify-content: flex-end;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
}

.accountcard {
  /* overflow-x: hidden; */
  height: 100%;
  /* max-height: calc(100vh - 150px); */
}
.account {
  color: blue;
  font-weight: bold;
  font-size: 23px;
  padding: 40px 40px 0;
}
.lot {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  width:100%;
}
.lot .lot-title p {
  color: black;
}
.lot .lot-title p span {
  color: rgb(31, 108, 196);
}
p {
  color: #161515;
}

.trade-body {
  border-radius: 30px;
}

.account1-titles {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
  gap: 3%;
  width: 100%;
  padding: 1em;
  align-items: center;
  justify-content: center;
  justify-items: center;
}




.account-1 {
  overflow: auto;
  height: 100%;
}

.submit-button {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.accoun-title {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.submit {
  width: 120px;
  text-transform: capitalize;
  background-image: linear-gradient(to right, #ef990f, #fd5c0a);
  color: white;
  font-family: 'Roboto', sans-serif;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  height: 40px;
  font-weight: bold;
  font-size: 15px;
}
.trade-list {
  display: inline;
  gap: 10px;
}
.trade-list .card-title h6 {
  font-size: 19px;
  color: black;
  font-weight: 600;
}
.fab-btn {
  margin-top: -3px;
  align-self: flex-end;
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 25px 60px 25px 25px;

  gap: 20px;
}
.cancel {
  width: 115px;
  text-transform: capitalize;
  color: black;
  background-color: transparent;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  height: 40px;
  font-weight: 20px;
  font-size: 15px;
  font-family: 'Roboto', sans-serif;
  padding: 5px;
}

.select-trade {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.token-btn {
  border: 1px solid rgb(31, 108, 196) !important;
  background-color: none;
  color: rgb(31, 108, 196) !important;
  text-align: center;
  text-transform: capitalize;
}
.trading-detail {
  display: flex;
  padding: 5px 5px;
  align-items: center;
  gap: 6.5px;
  background-color: #DFDFDF;
  border-radius: 10px;
  height: 65px;
}

.card-content {
  width: 100%;
}

@media screen and (max-device-width: 768px) {
  .card-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 425px) {
  .account1-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: -30px;
  }
  .account-container {
    display: flex;
    margin: -5px -2px;
    font-family: 'Roboto', sans-serif;
    min-width: 43px;
    padding: 1rem;
    border-radius: 5px !important;
    justify-content: center;
  }

  .account-2 p {
    color: blue;
    font-weight: bold;
    font-size: 18px;
    padding-top: 30px;
  }
 
  .card-content {
    display: flex;
    justify-content: center;
    margin: 10px 10px;
    padding-right: 30px;
    font-size: 18px;
  }

  .icon-container {
    display: flex;
    justify-content: flex-end;
    margin: 14px 20px;
    gap: 20px;
  }

  .trading-detail  {
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    color: black;
    gap: 4px;
  }
  .accountfab-btn {
    position: relative;
    top: 0;
  }
  .fab-btn {
    margin-top: -50px;
    padding: 5px 5px;
    position: absolute;
    color: white;
    border: none;
    cursor: pointer;
    margin-right: 20px;
  }

  /* .css-1nwjd9-MuiButtonBase-root-MuiFab-root {
    position: fixed;
    margin-bottom: 240px;
    margin-right: 280px;
  } */

  .trade-list .card-title h6 {
    font-size: 16px;
  }
  .card-title {
    display: flex;
    margin: 5px 0;
    justify-content: space-between;
  }
  .trading-detail2 {
    margin-left: 25px;
  }
  .lot {
    padding: 0;
    margin: 5px 0;
    align-items: center;
  }
  .lot p {
    margin-right: 10px;
  }
 
  .select-option {
    display: flex;
    flex-direction: column;
    width: 310px;
  } 
  .token-btn {
    border: 1px solid rgb(31, 108, 196) !important;
    background-color: none;
    color: rgb(31, 108, 196) !important;
    text-align: center;
  }
  .submit {
    padding: 5px;
    margin: 2px;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin: 25px 60px 25px 25px;
  }
  .select-trade {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

@media screen and (min-device-width: 1535px) and (max-device-width: 3000px) {

.trading-1{
  font-size: 13px;
}

}
@media screen and (min-device-width: 1320px) and (max-device-width: 1535px) {

  .trading-1{
    font-size: 12px;
  }
  
  }

  @media screen and (min-device-width: 20px) and (max-device-width: 1535px) {

  .trading-detail {
    display: flex;
    padding: 0px;
    align-items: center;
    gap: 0px;
    background-color: #DFDFDF;
    border-radius: 10px;
    height: 60px;
  }
  }

  .line_for_card{
    width: 1px;
    border: 1px solid #b1b0b0;
    height: 55px
  }
  @media screen and (min-device-width: 20px) and (max-device-width: 1535px) {

    .line_for_card{
   
   border: 1px solid #b1b0b0;
   height: 50px
    }
    
    }