.bodysummary{
 width: 100%;
 background-color: white;
 border-radius: 10px;
 /* height: 100vh; */
}
.summarytitle{
    font-size: larger;
    padding: 10px 10px 10px 25px;
    background-color: #338514;
    color: white;
    height: 6.5%;
    border-radius: 10px 10px 0px 0px;
}
.summarybody{
 width: 100%;
 display: flex;
 height: 100%;
 flex-wrap: wrap;
}
.summary_first_sub{
    width: 50%;
    height: 100%;
    padding: 2% 1% 2% 2%;
}
.summary_second_sub{
    width: 50%;
    height: 100%;
    padding: 2% 2% 2% 1%;
}
.first_sub{
    background-color: white;
   width: 100%;
   height: 100%;
   /* box-shadow:0 6px 10px 0px rgba(0, 0, 0, 0.2); */
   border-radius: 10px;
   overflow: scroll;
}
.second_sub{
    background-color:white;
   width: 100%;
   height: 100%;
   border-radius: 10px;
   /* box-shadow:0 6px 10px 0px rgba(0, 0, 0, 0.2); */
   overflow: scroll;
}
.first{
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:4.5%;
    border-bottom: 1px solid rgb(218 218 218);
}
/* .first:hover {
   color: rgb(15, 14, 14);
   background-color: rgb(224, 228, 223);
} */